import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "./styles/Header/header-styles.css";

import { useStore } from "./store";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { Navbar, MenuIcon, MenuListItem } from "./styles/Header/header-styles";

// Opens mobile navgiation drawer
function Header() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  function openDrawer() {
    setDrawerOpen(true);
  }

  function closeDrawer() {
    setDrawerOpen(false);
  }

  // Opens Donate Modal

  const openModal = useStore((state) => state.openModal);

  return (
    <nav className="header">
      <Navbar>
        <MenuIcon onClick={openDrawer} aria-label="Menu"/>
        <a href={__dirname} className="logo-anchor">
          <img
            className="logo"
            src={require("../ASSETS/AbideOne_Logo.png")}
            alt="AbideOne Logo"
          />
        </a>
        <List
          sx={{
            display: {
              mobile: "none",
              tablet: "none",
              desktop: "flex",
            },
            flexGrow: ".35",
          }}
        >
          <MenuListItem component="a" href="/">
            <ListItemText primary="Home" />
          </MenuListItem>
          <MenuListItem component="a" href="/livestream">
            <ListItemText primary="AbideOne Online" />
          </MenuListItem>
          <MenuListItem component="a" href="/media">
            <ListItemText primary="Devotionals" />
          </MenuListItem>
          <MenuListItem component="a" href="/about">
            <ListItemText primary="About" />
          </MenuListItem>
          <MenuListItem component="a" href="/contact-us">
            <ListItemText primary="Contact Us" />
          </MenuListItem>
          <MenuListItem component="a" onClick={openModal} sx={{"&:hover": {
          cursor: "pointer"}}}>
            <ListItemText primary="Donate" />
          </MenuListItem>
        </List>
      </Navbar>
      <Drawer
        open={isDrawerOpen}
        onClose={closeDrawer}
        variant="temporary"
        anchor="left"
      >
        <Box
          p={2}
          width="275px"
          height="100%"
          role="presentation"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridAutoRows: "50px 200px 100px auto",
          }}
        >
          <a href={__dirname} className="drawer-logo-anchor">
            <img
              className="drawer-logo"
              src={require("../ASSETS/AbideOne_Logo.png")}
              alt="AbideOne Logo"
            />
          </a>
          <MenuOpenRoundedIcon
            onClick={closeDrawer}
            sx={{
              gridColumn: "3/3",
              justifySelf: "flex-end",
              alignSelf: "center",
              fontSize: "2.5rem",
              paddingRight: "5%",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          />
          <List
            sx={{
              gridColumn: "1 / span 3",
              width: "100%",
            }}
          >
            <ListItem disablePadding component="a" href="/">
              <ListItemButton>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding component="a" href="/livestream">
              <ListItemButton>
                <ListItemText primary="AbideOne Online" />
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              component="a"
              href="/media"
              sx={{
                color: "inherit",
              }}
            >
              <ListItemButton>
                <ListItemText primary="Devotionals" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding component="a" href="/about">
              <ListItemButton>
                <ListItemText primary="About" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding component="a" href="/contact-us">
              <ListItemButton>
                <ListItemText primary="Contact Us" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={openModal}>
                <ListItemText primary="Donate" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Outlet/>
    </nav>
  );
}

export default Header;
