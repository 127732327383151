import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { main: "#C76D40" },
    secondary: { main: "#ffffff" },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      desktop: 1025,
    },
  },
  typography: {
    fontFamily: "Raleway, Sans-Serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: "17.5px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#7A330F",
            color: "#ffffff",
          },
        },
        text: {
          color: "#000000",
          textTransform: "none",
          "&:hover": {
            color: "#000000",
          },
        },
        outlined: {
          color: "#191919",
          borderColor: "#191919",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#191919",
            borderColor: "#191919",
            color: "#ffffff",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "#000000",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "blue",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000000",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "blue",
          },
        },
      },
    },
    MuiTypography: {
      styledOverrides: {
        root: {
          // Typography styles go here.
        },
      },
    },
  },
});

export default theme;
