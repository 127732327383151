import React from "react";
import "./Footer.css";
import Typography from "@mui/material/Typography";

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="footer-box">
      <Typography variant="body1" className="terms">
        <a href="/terms">Terms of Use & Privacy Policy</a>
      </Typography>
      <Typography variant="body1" className="copyright">
        Copyright 2019 - {year} © AbideOne Ministries, Inc.
      </Typography>
    </footer>
  );
}

export default Footer;
