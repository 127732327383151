// Header
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ListItem from "@mui/material/ListItem"

export const Navbar = styled(Toolbar)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  [theme.breakpoints.up("desktop")]: {
    display: "flex",
    flexDirection: "row",
  },
}));

export const MenuIcon = styled(MenuRoundedIcon)(({ theme }) => ({
  fontSize: "2.5rem",
  "&:hover": {
    cursor: "pointer",
  },
  [theme.breakpoints.up("desktop")]: {
    display: "none",
  },
}));

export const MenuListItem = styled(ListItem)(({ theme }) => ({
  [theme.breakpoints.up("desktop")]: {
    textAlign: "center"
  }
}));

