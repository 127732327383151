import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import ReactGA from "react-ga4";

// Google Analytics
ReactGA.initialize("G-1KZBX3QMQV");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
