import React, {
  useState,
  useCallback,
  lazy,
  Suspense,
  useEffect,
  useLayoutEffect,
} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../context/auth-context";

import Header from "../components/Header";
import Footer from "../components/Footer";
// import CookieNotice from "./CookieNotice.jsx";

import theme from "./theme";

import { useStore } from "./store";

const DonateModal = lazy(() => import("./Donate"));
const HomePageMain = lazy(() => import("../pages/HomePageMain.jsx"));
const WatchLive = lazy(() => import("../pages/WatchLive"));
const Media = lazy(() => import("../pages/Media"));
const About = lazy(() => import("../pages/About"));
const Error = lazy(() => import("../pages/Error"));
const AdminCenter = lazy(() => import("../pages/Admin"));
const ContactUs = lazy(() => import("../pages/ContactUs"));
const AdminDevotionalPack = lazy(() => import("../pages/AdminDevotionalPack"));
const EditDevotional = lazy(() => import("../pages/EditDevotional"));
const DevotionalPack = lazy(() => import("../pages/DevotionalPack"));
const AdminDevotionals = lazy(() => import("../pages/AdminDevotionals"));
const Auth = lazy(() => import("../pages/Auth"));
const Terms = lazy(() => import("../pages/Terms"));
const AdminLivestream = lazy(() => import("../pages/AdminLivestream"));

let logoutTimer;

// Main App function
function App() {
  // Function opens donate modal if there is the "#donate" parameter present in URL.
  const openModal = useStore((state) => state.openModal);
  let urlpath = window.location.href;
  if (urlpath.indexOf("donate") > -1) {
    openModal();
  }

  // AuthContext state
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);

  const login = useCallback((uid, token, expirationDate) => {
    setToken(token);
    setUserId(uid);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 3);
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useLayoutEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData?.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  // const [streamId, setStreamId] = useState("");

  // useLayoutEffect(() => {
  //   const storedData = localStorage.getItem("streamId");
  //   if (storedData) {
  //     setStreamId(storedData)
  //   }
  // }, [login]);

  // State for cookie notice

  // const [cookieNoticeState, setCookieNoticeState] = useState();

  // useEffect(() => {
  //   const cookieValue = window.localStorage.getItem("AcceptedCookie");
  //   setCookieNoticeState(cookieValue);
  //   console.log("Cooke notice state: " + cookieNoticeState)
  // }, [cookieNoticeState]);

  let routes;

  if (token) {
    routes = (
      <Routes>
        <Route path="admin" element={<AdminCenter />} />
        <Route
          path="/admin/create-devotional"
          element={<AdminDevotionalPack />}
        />
        <Route
          path="admin/edit-devotional/:dpid"
          element={<EditDevotional />}
        />
        <Route path="admin/devotionals" element={<AdminDevotionals />} />
        <Route path="/" element={<HomePageMain />} />
        <Route path="livestream" element={<WatchLive />} />
        <Route path="media" element={<Media />} />
        <Route path="about" element={<About />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="devotional/:dpid" element={<DevotionalPack />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="terms" element={<Terms />} />
        <Route path="/admin/livestream" element={<AdminLivestream />} />
        <Route path="*" element={<Error />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="livestream" element={<WatchLive />} />
        <Route path="admin" element={<Auth />} />
        <Route path="admin/create-devotional" element={<Auth />} />
        <Route path="admin/edit-devotional/:dpid" element={<Auth />} />
        <Route path="admin/devotionals" element={<Auth />} />
        <Route path="/" element={<HomePageMain />} />
        <Route path="livestream" element={<WatchLive />} />
        <Route path="media" element={<Media />} />
        <Route path="about" element={<About />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="devotional/:dpid" element={<DevotionalPack />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="auth" element={<Auth />} />
        <Route path="terms" element={<Terms />} />
        <Route path="/admin/livestream" element={<Auth />} />
        <Route path="*" element={<Error />} />
      </Routes>
    );
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          login: login,
          logout: logout,
        }}
      >
        <ThemeProvider theme={theme}>
          <Suspense
            fallback={
              <>
                <Header />
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <h1>Loading...</h1>
                  <CircularProgress
                    sx={{ color: "inherit", marginBottom: "675px" }}
                  />
                </Container>
                <Footer />
              </>
            }
          >
            <DonateModal />
            <Router>{routes}</Router>
            {/* {cookieNoticeState !== "true" && (
              <CookieNotice setCookieNoticeState={setCookieNoticeState} />
            )} */}
          </Suspense>
        </ThemeProvider>
      </AuthContext.Provider>
    </>
  );
}

export default App;
